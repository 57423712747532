import { useCallback, useEffect, useMemo, useState, } from "react";
import { Button, Row, Col, Form, Label, FormGroup, Spinner, FormText, CustomInput, Container, ButtonGroup, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges, useChangesArray } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useSaveQuestionnaireCallback } from '../../api/main/questionnaires/useSaveQuestionnaireCallback';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import moment from "moment";
import { defaultTemplateId, QuestionnaireType, questionnaireTypeDisplayName } from "../../api/main/models/codeOnly/QuestionnaireType";
import { useEditQuestionnaireSupportingData } from "../../api/main/questionnaires/viewModels/useEditQuestionnaireSupportingData";
import { useEditQuestionnaireViewModel } from "../../api/main/questionnaires/viewModels/useEditQuestionnaireViewModel";
import { QuestionnaireSection } from "../../api/main/models/QuestionnaireSection";
import { useSaveQuestionnaireSectionCallback } from "../../api/main/questionnaireSections/useSaveQuestionnaireSectionCallback";
import { useDeleteQuestionnaireSectionCallback } from "../../api/main/questionnaireSections/useDeleteQuestionnaireSectionCallback";
import { useValidatorArrayCallback } from "../../shared/validator-react-contrib/useValidatorArrayCallback";
import { SectionsTab } from "./edit/questionnaireSections/SectionsTab";
import { useSaveQuestionCallback } from "../../api/main/questions/useSaveQuestionCallback";
import { useDeleteQuestionCallback } from "../../api/main/questions/useDeleteQuestionCallback";
import { Question } from "../../api/main/models/Question";
import { LearningUnitDetails } from "./shared/LearningUnitDetails";
import { learningUnitDefaultValues } from "../../api/main/models/LearningUnit";
import { useSaveLearningUnitCallback } from "../../api/main/learningUnits/useSaveLearningUnitCallback";
import { useDebouncedCallback } from "use-debounce/lib";
import { QuestionType } from "../../api/main/models/codeOnly/QuestionType";
import { ValidatedElasticInput } from "../../shared/validatedInputExtended";
import "./editQuestionnaireBase.scss";
import { QuestionResponseType } from "../../api/main/models/codeOnly/QuestionResponseType";
import { useDeleteQuestionnaireCallback } from "../../api/main/questionnaires/useDeleteQuestionnaireCallback";
import { Guid } from "guid-string";
import { QuestionnaireNavigation } from "../subscriptionQuestionnaires/questionnaires/QuestionnaireNavigation";
import { PeopleTab } from "./edit/questionnairePeople/PeopleTab";
import { ScheduleTab } from "./edit/questionnaireSchedule/ScheduleTab";
import { ReviewTab } from "./edit/questionnaireReview/ReviewTab";
import { useToggleState } from "use-toggle-state";
import { ConfirmCancelQuestionnaireModal } from "./ConfirmCancelQuestionnaireModal";
import { QuestionnaireRespondentSession, questionnaireRespondentSessionDefaultValues } from "../../api/main/models/QuestionnaireRespondentSession";
import { useAddQuestionnaireUserCallback } from "../../api/main/questionnaireRespondentSessions/useAddQuestionnaireUserCallback";
import { QuestionnaireRespondentSessionCreateInput } from "../../api/main/generated/globalTypes";
import { useUploadBlobCallback } from "../../api/main/blobReferences/useUploadBlobCallback";
import { importQuestionnaireQuestionsMutation_importQuestionnaireQuestions } from "../../api/main/generated/importQuestionnaireQuestionsMutation";
import { useImportQuestionnaireQuestionsCallback } from "../../api/main/questions/useImportQuestionnaireQuestionsCallback";
import { ImportExportQuestionnaireQuestionsModal } from "./edit/importExportQuestionnaireModals/ImportExportQuestionnaireQuestionsModal";
import { useSendAllQuestionnaireInviteCallback } from "../../api/main/questionnaires/useSendAllQuestionnaireInviteCallback";
import { AnalysisType } from "../../api/main/models/AnalysisType";
import { useSaveAnalysisTypeCallback } from "../../api/main/analysisTypes/useSaveAnalysisTypeCallback";
import { useDeleteAnalysisTypeCallback } from "../../api/main/analysisTypes/useDeleteAnalysisTypeCallback";
import { useQuestionnaireSections } from "../../api/main/questionnaireSections/useQuestionnaireSections";
import { useQuestionnaireAnalysisTypeLinks } from "../../api/main/questionnaireAnalysisTypeLinks/useQuestionnaireAnalysisTypeLinks";
import { QuestionnaireAnalysisTypeLink } from "../../api/main/models/QuestionnaireAnalysisTypeLink";
import { useSaveQuestionnaireAnalysisTypeLinkCallback } from "../../api/main/questionnaireAnalysisTypeLinks/useSaveQuestionnaireAnalysisTypeLinkCallback";
import { useDeleteQuestionnaireAnalysisTypeLinkCallback } from "../../api/main/questionnaireAnalysisTypeLinks/useDeleteQuestionnaireAnalysisTypeLinkCallback";

interface EditQuestionnaireBaseProps {
    isSubscription?: boolean,
    isCreate?: boolean,
}

export const CreateQuestionnaire = () => (<EditQuestionnaireBase isCreate={true} />);

/**
 * Base for Editing a questionnaire.
 */
export const EditQuestionnaireBase = (props: EditQuestionnaireBaseProps) => {
    const {
        isSubscription = true, // tells us if we are working within a subscription
        isCreate = false, // tells us if we are creating a new questionnaire by editing a cloned one or genuinly editing an existing questionnaire
    } = props;
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();

    // if we find a department id in the params we are working at department admin level -  then we won't let the user change it on the questionnaire, we will default it
    const { id, departmentId, type, activeTab } = useParams<{ id: string | undefined, departmentId: string | undefined, type: string | undefined, activeTab: string | undefined; }>();

    // main data load
    const { data: {
        model: storeModel,
        sections: storeSections,
        questions: storeQuestions,
        questionnaireRespondentSessions: storeSessions,
        learningUnit: storeModelLearningUnit,
    }, isLoading: _isLoading, errors: loadErrors } = useEditQuestionnaireViewModel(id);

    // get QuestionnaireAnalysisTypeLinks
    const {
        data: {
            items: storeQuestionnaireAnalysisTypeLinks,
        }, isLoading: isLoadingQuestionnaireAnalysisTypeLinks, errors: loadQuestionnaireAnalysisTypeLinksErrors
    } = useQuestionnaireAnalysisTypeLinks({ questionnaireId: id });

    // supporting data
    const { data: { subscription, subscriptionDepartments, analysisTypes, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditQuestionnaireSupportingData({ subscriptionId: currentUserSubscriptionId ?? Guid.empty });

    // Get the default Template's sections so we can compare section names when editing
    const { data: { items: defaultSections }, isLoading: isLoadingDefaultSections, errors: loadDefaultSectionsErrors } = useQuestionnaireSections({ questionnaireId: defaultTemplateId });

    const isLoading = _isLoading || isLoadingSupportingData || isLoadingDefaultSections;

    const { model, change, changes } = useChanges(storeModel, undefined);
    const [save, { errors: _saveErrors }] = useSaveQuestionnaireCallback();
    const [saveLearningUnit, { errors: saveErrorsLearningUnit }] = useSaveLearningUnitCallback();
    const history = useHistory();
    const [remove] = useDeleteQuestionnaireCallback();

    // need to know if we are working on a template so that we can hide people tab and make defaults and progress appropriate
    const isTemplate = useMemo(() => {
        if (!model) {
            return false;
        }
        return !!type && type === 'Template' ? true : model.isTemplate;
    }, [type, model]);

    const isSelfService = useMemo(() => {
        if (!model) {
            return false;
        }
        return (!!type && type === QuestionnaireType.SelfService) || model.questionnaireType === QuestionnaireType.SelfService;
    }, [type, model]);

    //learningUnit
    const { model: _modelLearningUnit, change: changeLearningUnit, changes: changesLearningUnit } = useChanges(storeModelLearningUnit, undefined);

    // Only have modelLearningUnit have a value if we really have one because a lot of our logic uses !modelLearningUnit to check if we have one we need to work with.
    const modelLearningUnit = useMemo(() => {
        if (!_modelLearningUnit.id) {
            return undefined;
        }

        return _modelLearningUnit;
    }, [_modelLearningUnit]);
    const isLearningUnit = !!modelLearningUnit && !modelLearningUnit.archived;

    // if there's no subscription id we are at skillshub admin level so dont set a subscription Id
    const learningUnitSubscriptionId = model.id !== defaultTemplateId && !!currentUserSubscriptionId && !!isSubscription ? currentUserSubscriptionId : undefined;

    // Analysis Types
    const analysisTypeManager = useChangesArray<AnalysisType, string>(analysisTypes, item => item.id);
    const [saveAnalysisType] = useSaveAnalysisTypeCallback();
    const [removeAnalysisType] = useDeleteAnalysisTypeCallback();
    // Sections.
    const sectionsManager = useChangesArray<QuestionnaireSection, string>(storeSections, item => item.id);
    const [saveQuestionnaireSection] = useSaveQuestionnaireSectionCallback();
    const [removeQuestionnaireSection] = useDeleteQuestionnaireSectionCallback();
    // Questions.
    const questionsManager = useChangesArray<Question, string>(storeQuestions, item => item.id);
    const [saveQuestion] = useSaveQuestionCallback();
    const [removeQuestion] = useDeleteQuestionCallback();
    // Questionnaire-AnalysisType Links
    const questionnaireAnalysisTypeLinksManager = useChangesArray<QuestionnaireAnalysisTypeLink, string>(storeQuestionnaireAnalysisTypeLinks, item => item.id);
    const [saveQuestionnaireAnalysisTypeLink] = useSaveQuestionnaireAnalysisTypeLinkCallback();
    const [removeQuestionnaireAnalysisTypeLink] = useDeleteQuestionnaireAnalysisTypeLinkCallback();
    // As we don't have a model that stores everything we need for each user, we've created one called UserData (see below).
    // We store an array of these and render their details on screen.  We will split this back out into
    // users and respondant sessions when we save.
    const usersManager = useChangesArray<UserData, string>([], item => item.key);

    // create a new QuestionnaireRespondentSession for each user manually entered and create the user if it doesn't exist
    const [saveUser, { errors: saveUserErrors }] = useAddQuestionnaireUserCallback();

    const saveErrors = _saveErrors || saveUserErrors || saveErrorsLearningUnit;

    // Validation
    //
    const [validateAnalysisType, validationErrorsAnalysisType] = useValidatorArrayCallback<AnalysisType>((myModel, validation, fieldsToCheck) => {

        const rules = {
            analysisTypeName: () => !myModel.name ? t('editQuestionnaireBase.vaidateAnalysisTye.nameRequired', 'Name is required') : '',
            baseAmountDefault: () => myModel?.baseAmountDefault === undefined || myModel?.baseAmountDefault === null ? t('editQuestionnaireBase.vaidateAnalysisTye.baseAmountDefaultRequired', 'Default Base Amount is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

    }, []);

    const [validateLearningUnit, validationErrorsLearningUnit] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            endDate: () => moment(modelLearningUnit?.startDate).toISOString() > moment(modelLearningUnit?.endDate).toISOString() ? t('learningUnitDetails.endDate', 'End date must be after the start date') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [modelLearningUnit]);

    const [validateQuestionnaireSection, questionnaireSectionValidationErrors] = useValidatorArrayCallback<QuestionnaireSection>((myModel, validation, fieldsToCheck) => {
        const rules = {
            // Section must have a name.
            sectionName: () => !myModel.name ? t('editQuestionnaireBase.validateSection.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

    }, []);

    const baseRoute = useMemo(() => {
        if (!isSubscription) {
            return '/administration/questionnaires/Template/';
        }
        if (!!departmentId) {
            return `/departmentQuestionnaires/${departmentId}/`;
        }
        if (!!isSubscription) {
            return '/subscriptionQuestionnaires/';
        }
        //default to subscription level
        return '/manage/questionnaires/';
    }, [isSubscription, departmentId]);

    const numberOfMultipleChoices = useCallback((myModel) => {
        let ret = 0;
        if (!!myModel.multipleChoiceResponse1) { ret++; }
        if (!!myModel.multipleChoiceResponse2) { ret++; }
        if (!!myModel.multipleChoiceResponse3) { ret++; }
        if (!!myModel.multipleChoiceResponse4) { ret++; }
        if (!!myModel.multipleChoiceResponse5) { ret++; }
        if (!!myModel.multipleChoiceResponse6) { ret++; }
        if (!!myModel.multipleChoiceResponse7) { ret++; }
        if (!!myModel.multipleChoiceResponse8) { ret++; }
        return ret;
    }, []);

    const [validateQuestion, questionValidationErrors] = useValidatorArrayCallback<Question>((myModel, validation, fieldsToCheck) => {

        const rules = {
            // question must have the main question text.
            questionText: () => !myModel?.questionText ? t('editQuestion.questionTextRequired', 'A question is required') : '',

            // question cannot have a blank reinforcement question text if reinforcement is required
            // NOTE: reinforcements removed but this left in case we ever add them back
            // reinforcementText: () => !!myModel?.isReinforcementRequired && !myModel?.reinforcementText ? t('editQuestion.reinforcementTextRequired', 'A reinforcement question is required') : '',

            // if the qestion type is not Measure then we must have a minimum number of answers
            maxTextBoxes: () => myModel?.questionType !== QuestionType.Measure && !myModel.maxTextBoxes && myModel.responseType !== QuestionResponseType.MultipleChoice && myModel.responseType !== QuestionResponseType.PickOne ? t('editQuestion.minTextBoxesRequired', 'A maximum number of answers is required for this type of question') : '',

            // For scale questions the max scale number cannot be less than 1 or more than 10
            scaleMax: () =>
                myModel?.questionType === QuestionType.Measure &&
                    (myModel.responseType === QuestionResponseType.ComparisonScale || myModel.responseType === QuestionResponseType.Scale) &&
                    (myModel.scaleMax > 10 || myModel.scaleMax < 1) ? t('editQuestion.maxScaleNumberRequired', 'The scale maximum must be between 1 and 10') : '',

            // For Multiple Choice Questions at lease two reponses must be given.
            multipleChoiceResponse1: () => (myModel.responseType === QuestionResponseType.MultipleChoice || myModel.responseType === QuestionResponseType.PickOne) && numberOfMultipleChoices(myModel) < 2
                ? t('editQuestionnaireBase.error.multipleChoiceQuestion', 'Multiple choice questions must have a minimum of two answers.') : '',

            // Questions must have a analysis type
            analysisType: () => !myModel?.analysisTypeId ? t('editQuestion.analysisTypeRequired', 'A analysis type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

    }, [numberOfMultipleChoices,]);

    const questionnaireTypesArray = Object.values(QuestionnaireType);

    const textSuffix = isTemplate ? t('editQuestionnaireBase.template', 'Template') : t('editQuestionnaireBase.campaign', 'Campaign');
    // An array of which stages to highlight as already having the minimum information completed on the questionnaire.
    // We also use this to decide whether to allow progression through the stages using the banner buttons or the continue button
    const completedStages = useMemo(() => {
        let ret: string[] = [];

        if (!model || !questionsManager) {
            return ret;
        }

        // questionnaire may have been cloned but until it has a name we don't want to show any stages as complete
        if (!model.name) {
            return ret;
        }

        // campaign stage - we have a name
        if (!!model.name) {
            ret.push('campaign');
        }
        // questionnaire stage - we have at least 1 question
        if (questionsManager.model.length > 0) {
            ret.push('questionnaire');
        }
        // people stage  - at least one person unless it is a template or self service in which case we don't care
        if ((!!storeSessions && storeSessions.length > 0) || (usersManager.model.length > 0 && !!usersManager.model[0].email) || isSelfService || !!isTemplate) {
            ret.push('people');
        }
        // schedule stage
        // we have a default date so there will always be one so if the people button is completed so is this one
        if ((!!storeSessions && storeSessions.length > 0) || (usersManager.model.length > 0 && !!usersManager.model[0].email) || (isTemplate && questionsManager.model.length > 0) || (isSelfService && questionsManager.model.length > 0)) {
            ret.push('schedule');
        }

        // review and send stage
        // if this is set we will consider the create completed and from then on assume edit
        if (model.isPublished) {
            ret.push('review');
        }

        return ret;
    }, [model, questionsManager, storeSessions, usersManager, isTemplate, isSelfService,]);

    // use this instead of isCreate to treat the edit as a create if all the stages of creating are not complete
    const isTreatAsCreate = useMemo(() => {
        if (isCreate) {
            return true;
        }

        if (!completedStages) {
            return false;
        }

        return !completedStages.find(item => item === 'review');

    }, [isCreate, completedStages]);

    const displayTitle = useMemo(() => {
        if (!model) {
            return;
        }

        // displaytext will be empty string, or self-service so that we can call everything a campaign, or template
        let questionnaireTypeDisplayText = '';
        if (!model?.isTemplate && !!type && type === QuestionnaireType.SelfService) {
            questionnaireTypeDisplayText = questionnaireTypeDisplayName(type as QuestionnaireType, t) + ' ';
        }


        if (isTreatAsCreate && !isTemplate) {
            return t('editQuestionnaireBase.title.create', `Create A ${questionnaireTypeDisplayText}${textSuffix}`);
        } else if (isTreatAsCreate && isTemplate) {
            return t('editQuestionnaireBase.title.create.template', `Create A Template`);
        } else if (!isTreatAsCreate && isTemplate) {
            return t('editQuestionnaireBase.title.create.template', `Edit A Template`);
        } else {
            return t('editQuestionnaireBase.title.edit', `Edit A ${questionnaireTypeDisplayText}${textSuffix}`);
        }

    }, [model, textSuffix, type, t, isTemplate, isTreatAsCreate]);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {

        const rules = {
            name: () => !model?.name ? t('editQuestionnaireBase.nameRequired', 'Name is required') : '',
            questionnaireType: () => !model?.questionnaireType ? t('editQuestionnaireBase.questionnaireTypeRequired', 'A type is required for a template') : '',

            // Validate sections
            questionnaireSection: () => sectionsManager.model.filter(it => !validateQuestionnaireSection(it)).length ? t('editQuestionnaireBase.sectionsInvalid', 'One or more of the sections are invalid') : '',
            // Validate questions
            question: () => questionsManager.model.filter(it => !validateQuestion(it)).length ? t('editQuestionnaireBase.questionsInvalid', 'One or more of the questions are invalid') : '',
            // No need to Validate userData
            // userData: () => usersManager.model.filter(it => !validateUserData(it)).length ? t('questionnaireUsersAdd.userDataInvalid', 'One or more of the Users are invalid') : '',

            endDate: () => model?.endDate < moment(model.startDate).add(1, 'days') ? t('editQuestionnaireBase', 'The end date must be after the start date') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

    }, [
        model,
        sectionsManager, validateQuestionnaireSection,
        questionsManager, validateQuestion,
    ]);

    const lowerLevelErrors = useMemo(() => {
        if (!validationErrors) {
            return;
        }

        if (!!validationErrors.question && !!validationErrors.question.length) {

            let questionErrors = '';

            for (let i = 0; i < validationErrors.question.length; i++) {
                questionErrors = questionErrors + validationErrors.question[i] + (i < validationErrors.question.length - 1 ? ', ' : '');
            }

            return questionErrors;

        }

        return;
    }, [validationErrors]);

    // Initialise the usersManager with 5 blank entries
    const initialiseUsersManager = useCallback(() => {

        // Set up a new questionnaireRespondentSession
        const newSession = (): Partial<QuestionnaireRespondentSession> => ({
            ...questionnaireRespondentSessionDefaultValues(),
            questionnaireId: model?.id,
            subscriptionId: model?.subscriptionId,
        });
        // New user data item for when a new user is added in the array of 5
        const newItem: Partial<UserData> = { ...userDataDefaultValues(), session: newSession() };

        for (let i = 0; i < usersManager.model.length; i++) {
            usersManager.removeFor(usersManager.model[i].key);
        }

        for (let i = 0; i < 5; i++) {
            usersManager.addFor({ ...newItem, key: (i + 1).toString() });
        }

    }, [usersManager, model]);
    useEffect(() => {
        if (!model || !model.id) {
            return;
        }

        if (!usersManager.model.length) {
            initialiseUsersManager();
        }
    }, [usersManager.model.length, initialiseUsersManager, model]);

    // all saves of sections and learning unit and questions and users below the questionnaire come back to here to save everything at once
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { goBack?: boolean, navigateTo?: string, }) => {
        if (!model.archived && (!validate() || (isLearningUnit && !validateLearningUnit()))) {
            return false;
        }

        // Save the main model
        await save(model.id, changes, false);

        //save the learningUnit
        if (modelLearningUnit) {
            await saveLearningUnit(modelLearningUnit.id, changesLearningUnit, !storeModelLearningUnit);
        }

        // Save the analysis Types
        for (const item of analysisTypeManager.added) { await saveAnalysisType(item.id, analysisTypeManager.changesFor(item.id), true); }
        for (const item of analysisTypeManager.updated) { await saveAnalysisType(item.id, analysisTypeManager.changesFor(item.id), true); }
        for (const item of analysisTypeManager.removed) { await removeAnalysisType(item.id); }
        analysisTypeManager.markAsSaved();

        // Save the sections.
        for (const item of sectionsManager.added) { await saveQuestionnaireSection(item.id, sectionsManager.changesFor(item.id), true); }
        for (const item of sectionsManager.updated) { await saveQuestionnaireSection(item.id, sectionsManager.changesFor(item.id), false); }
        for (const item of sectionsManager.removed) { await removeQuestionnaireSection(item.id); }
        sectionsManager.markAsSaved();

        // Save the questions.
        for (const item of questionsManager.added) { await saveQuestion(item.id, questionsManager.changesFor(item.id), true); }
        for (const item of questionsManager.updated) { await saveQuestion(item.id, questionsManager.changesFor(item.id), false); }
        for (const item of questionsManager.removed) { await removeQuestion(item.id); }
        questionsManager.markAsSaved();

        // Save the questionnaire-AnalysisType Links
        for (const item of questionnaireAnalysisTypeLinksManager.added) { await saveQuestionnaireAnalysisTypeLink(item.id, questionnaireAnalysisTypeLinksManager.changesFor(item.id), true); }
        for (const item of questionnaireAnalysisTypeLinksManager.updated) { await saveQuestionnaireAnalysisTypeLink(item.id, questionnaireAnalysisTypeLinksManager.changesFor(item.id), false); }
        for (const item of questionnaireAnalysisTypeLinksManager.removed) { await removeQuestionnaireAnalysisTypeLink(item.id); }
        questionnaireAnalysisTypeLinksManager.markAsSaved();

        // Save the users - there will only be added users.
        for (const item of usersManager.added) {
            if (!!item.email) {
                await saveUser(
                    { ...item.session as QuestionnaireRespondentSessionCreateInput, lineManagerEmail: item.lineManagerEmail, lineManagerName: item.lineManagerName, subscriptionDepartmentId: item.departmentId },
                    item.firstName,
                    item.lastName
                );
            }
        }
        for (const item of usersManager.updated) {
            if (!!item.email) {
                await saveUser(
                    { ...item.session as QuestionnaireRespondentSessionCreateInput, lineManagerEmail: item.lineManagerEmail, lineManagerName: item.lineManagerName, subscriptionDepartmentId: item.departmentId },
                    item.firstName,
                    item.lastName
                );
            }
        }
        usersManager.markAsSaved();
        initialiseUsersManager();

        // if goBack is false we are saving as we go along and we are not finished
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else if (options?.goBack) {
            history.goBack();
        }
        return true;
    }, [
        validate, save, model, changes, history,
        sectionsManager, saveQuestionnaireSection, removeQuestionnaireSection,
        questionsManager, saveQuestion, removeQuestion, modelLearningUnit,
        usersManager, saveUser, analysisTypeManager, saveAnalysisType, removeAnalysisType, initialiseUsersManager
    ]);

    const saveFormDebounce = useDebouncedCallback((options?: { goBack?: boolean, navigateTo?: string, }) => saveForm(options), 500);


    const [confirmCancelModalIsOpen, toggleConfirmCancelModal] = useToggleState();

    // which button is active at the top of the screen - may be default on entry or may be set directly or indirectly by user action
    const [activeStage, setActiveStage] = useState<string>('');

    // Sets the default active stage for the buttons at the top of create/edit.
    // We only want this to set once so that it does not interfere with the normal setting of the activeStage, so check all the dependencies carefully
    useEffect(() => {
        // if activeStage is already set don't change it - this is what makes it happen only once
        // if there's no model - no action - nothing's loaded yet
        // if storeQuestions not loaded yet - no action - because we need to know if we have any questions
        if (!!activeStage || !model.id || !storeQuestions) {
            return;
        }

        if (!!activeTab) {
            // may have come back from some other screen that remembers the active stage in the activeTab param - usually Preview
            setActiveStage(activeTab);
        } else if (!model.name) {
            // default to the first stage if there is no questionnaire name yet
            // all other buttons are locked
            setActiveStage('campaign');
        } else if (isTemplate) {
            // also default to the first stage when editing a template 
            setActiveStage('campaign');
        } else if (!storeQuestions.length) {
            // in edit, if we have a name but no questions default to questions
            // tabs after the questionnaire stage will be locked
            setActiveStage('questionnaire');
        } else if (isSelfService) {
            // fallback on to the first stage if this is a self service campaign as there is no people stage
            setActiveStage('campaign');
        } else {
            // fallback default is people as this is usually what a campaign admin will be working on when editing
            setActiveStage('people');
        }

    }, [model.id, model.name, setActiveStage, activeStage, activeTab, isTemplate, isTreatAsCreate, storeQuestions, isSelfService,]);

    // Processing for navigation buttons and continue button
    const handleQuestionnaireNavigation = useCallback((link: string) => {
        if (validate()) {
            saveForm({ goBack: false });
            setActiveStage(link);
        }
    }, [validate, setActiveStage, saveForm]);

    // Code Below is for importing Questions

    // If anyone has started the Questionnaire, additional users must be added manually
    // If we have any respondant sessions for this questionnaire that have a start date on disable/hide import button
    const respondantSessionsWithStartDate = useMemo(() => {
        if (!storeSessions) {
            return [];
        }
        return storeSessions?.filter(item => !!item.startDate) ?? [];
    }, [storeSessions]);

    // Uploading blobs.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();

    // Import Questions from an import file
    const [importQuestions, { errors: importQuestionsErrors }] = useImportQuestionnaireQuestionsCallback();

    // Results from an attempted import.
    const [importResults, setImportResults] = useState<Array<importQuestionnaireQuestionsMutation_importQuestionnaireQuestions> | undefined>(undefined);

    // Perform the actual Import
    const [performImport, { isExecuting: isImporting, errors: performImportErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Import from the upload
        const results = await importQuestions({ blobId: blob.id, questionnaireId: model.id });
        setImportResults(results);
    }, [uploadBlob, model, setImportResults, importQuestions]);

    // Import Export Modal
    const [importExportModalIsOpen, toggleImportExportModal] = useToggleState();

    // Close Import Export Modal and Reload Page
    const closeImportExportModal = useCallback(() => {
        if (!importExportModalIsOpen) {
            return;
        }
        // if we have no import results just close the modal
        if (!importResults) {
            // Close the Modal
            toggleImportExportModal();
        }
        // if we do have import results then reload the page after importing
        if (!!importResults) {
            // Close the Modal
            toggleImportExportModal();
            // Save the questionnaire
            saveFormDebounce();

            window.location.replace(!isCreate ? `${baseRoute}edit/${model.id}/questionnaire` : `${baseRoute}create/${model.id}/questionnaire`);
        }
    }, [importExportModalIsOpen, toggleImportExportModal, saveFormDebounce, importResults, baseRoute, model, isCreate]);


    // Send All Users An Invite Only at the end of the process at the review page
    const [sendAllInvite] = useSendAllQuestionnaireInviteCallback();
    // Callback to be used to set  the isPublished flag to true and the sendAllInvite is triggered if appropriate.
    const handleIsPublishedClick = useCallback(() => {
        if (isTemplate) {
            // templates do not get published
            return;
        }

        if (model.isPublished) {
            // don't publish twice
            return;
        }

        if (!completedStages.find(item => item === 'schedule')) {
            // need to complete all stages up to and including schedule before publish
            return;
        }

        change({ isPublished: true });

        const today = new Date();

        if (!isSelfService && !model.hasSentEmails && model.startDate <= today) {
            // don't need to send invites for self service, if we have already sent them, or if the start date is in the future.
            sendAllInvite(model.id);

            change({ hasSentEmails: true });
        }

    }, [model, change, sendAllInvite, isTemplate, completedStages, isSelfService,]);


    const handleSaveNavigateTo = useCallback(() => {
        if (!isSubscription) {
            return baseRoute;
        }

        let ret = '';
        let workingBaseRoute = baseRoute.replace('departmentQuestionnaires', 'departmentQuestionnaires/list');

        if (isTemplate) {
            ret = `${workingBaseRoute}Template`;
        } else {
            ret = `${workingBaseRoute}${model?.questionnaireType}`;
        }

        return ret;
    }, [model, isTemplate, baseRoute, isSubscription]);


    // handle department change - department can be changed or cleared completely
    const handleDepartmentChange = useCallback((inputValue: string) => {
        if (!inputValue) {
            change({ subscriptionDepartmentId: null });
        } else {
            change({ subscriptionDepartmentId: inputValue });
        }
    }, [change]);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <ConditionalFragment showIf={!isLoading}>
                        <Col className="text-center">
                            <h1>
                                {displayTitle}
                            </h1>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isLoading || isLoadingQuestionnaireAnalysisTypeLinks}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row className="mt-2">

                    {/***************************/}
                    {/* main navigation buttons */}

                    <ConditionalFragment showIf={!isLoading}>
                        <Col>
                            <Container>
                                <div className="questionnaire-navigation-container">
                                    <QuestionnaireNavigation
                                        id={id ?? ''}
                                        activeLink={activeStage}
                                        onNavigate={link => handleQuestionnaireNavigation(link)}
                                        isCreate={isTreatAsCreate}
                                        completedStages={completedStages}
                                        isTemplate={isTemplate}
                                        questionnaireType={model.questionnaireType}
                                    />
                                </div>
                            </Container>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer className="questionnaire-main-container">
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, loadSupportingDataErrors, performImportErrors, loadDefaultSectionsErrors,
                    importQuestionsErrors, uploadBlobErrors, loadQuestionnaireAnalysisTypeLinksErrors,]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm({ goBack: model.id.toLowerCase() !== defaultTemplateId.toLowerCase() }); }}>

                    <Row>
                        <Col className="text-center">
                            <h1 className="questionnaire-name">
                                {model.name ?? ''}
                            </h1>
                        </Col>
                    </Row>


                    {/* ************************************************************************************************* */}
                    {/* campaign section of edit questionnaire - shows if user selects campaign or by default for create. */}
                    {/* ************************************************************************************************* */}

                    <ConditionalFragment showIf={activeStage === 'campaign'}>
                        <Row>
                            <Col>
                                <h2> {isTemplate ? t('editQuestionnaireBase.template', 'Template') : t('editQuestionnaireBase.heading.campaign', 'Campaign Information')}</h2>
                            </Col>
                            <Col xs="auto">
                                {
                                    // no continue button in edit, only during create
                                    isTreatAsCreate ?
                                        <Button color="primary" disabled={!completedStages.find(item => item === 'campaign')} onClick={e => {
                                            if (validate()) {
                                                saveFormDebounce();
                                                setActiveStage('questionnaire');
                                            }
                                        }}>
                                            {t("common.continue", "Continue")}
                                            <> </>
                                            <FontAwesomeIcon icon="caret-right" />
                                        </Button>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label htmlFor="name">{t('editQuestionnaireBase.name', `${textSuffix} Title`)}</Label>
                                    <ValidatedInput name="name" type="text" value={model.name ?? ''}
                                        onChange={e => change({ name: e.currentTarget.value })}
                                        onBlur={e => { validate('name'); }}
                                        validationErrors={validationErrors['name']} />
                                </FormGroup>

                                {/* Only show the template description field if we are at subscription admin level. */}
                                <ConditionalFragment showIf={model.isTemplate}>
                                    <FormGroup>
                                        <Label htmlFor="templateDescription">{t('editQuestionnaireBase.templateDescription', 'Template Description')}</Label>
                                        <ValidatedElasticInput name="templateDescription" type="textarea" value={model.templateDescription ?? ''}
                                            onChange={e => change({ templateDescription: e.currentTarget.value })}
                                            onBlur={e => { validate('templateDescription'); }}
                                            validationErrors={validationErrors['templateDescription']} />
                                    </FormGroup>
                                </ConditionalFragment>

                                <FormGroup>
                                    <Label htmlFor="description">{t('editQuestionnaireBase.description', 'Campaign Instructions')}</Label>
                                    <ValidatedElasticInput name="description" type="textarea" value={model.description ?? ''}
                                        placeholder={"Campaign instructions will help explain what the campaign is trying to achieve and give participants a better understanding of why they need to complete the questionnaire."}
                                        onChange={e => change({ description: e.currentTarget.value })}
                                        onBlur={e => { validate('description'); }}
                                        validationErrors={validationErrors['description']} />
                                    <FormText>
                                        {t('editQuestionnaireBase.description.help', 'Adding a description is optional, campaign descriptions will appear underneath the campaign title.')}
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col xs={12} sm={6}>
                                {/* Only show the department field if we are at subscription admin level. */}
                                <ConditionalFragment showIf={!!isSubscription && !departmentId && subscriptionDepartments?.length > 0}>
                                    <FormGroup>
                                        <Label>{t('editQuestionnaireBase.isDepartment', 'Do you want to limit your campaign to a single department?')}</Label>
                                        <> </>
                                        <span data-toggle="tooltip" title={t('editQuestionnaireBase.isDepartment.tooltip', "if you select a department then your department admin will be able to manage this campaign.")}><FontAwesomeIcon icon="info-circle" /></span>
                                        <ValidatedInput
                                            name="subscriptionDepartmentId" type="select"
                                            value={model?.subscriptionDepartmentId ?? ''}
                                            onChange={e => handleDepartmentChange(e.currentTarget.value)} onBlur={e => validate('subscriptionDepartment')} validationErrors={validationErrors['subscriptionDepartment']}>
                                            <option key={null} value="">{t('editQuestionnaireBase.departmentPlaceholder', 'N/A')}</option>
                                            {
                                                subscriptionDepartments?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </ValidatedInput>
                                        <FormText>
                                            {t('editQuestionnaireBase.departmentHelp1', 'Picking a department will limit this campaign to the department you choose.')}
                                        </FormText>
                                    </FormGroup>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!isTemplate}>
                                    <FormGroup>
                                        <Label>{t('editQuestionnaireBase.isLearningUnit', 'Does your campaign relate to a learning event or resource?')}</Label>
                                        <> </>
                                        <span data-toggle="tooltip" title={t('editQuestionnaireBase.isLearningUnit.tooltip', "(e.g. course, eLearning, book etc)")}><FontAwesomeIcon icon="info-circle" /></span>
                                        <CustomInput type="switch" id="isLearningUnit" label={!!isLearningUnit ? t('common.yes', 'Yes') : t('common.no', 'No')}
                                            checked={!!isLearningUnit ?? false} onChange={e => {
                                                const newIsLearningUnit = !isLearningUnit;

                                                // If we are checked, we want to create or unarchive the learningUnit (we only unarchive it if it was loaded/created this session).
                                                if (newIsLearningUnit) {
                                                    // If we don't have a learningUnit model, apply a change to create one by giving it an id.
                                                    if (!modelLearningUnit) {
                                                        changeLearningUnit({ ...learningUnitDefaultValues(), subscriptionId: learningUnitSubscriptionId ?? undefined, questionnaireId: model.id });
                                                    } else {
                                                        changeLearningUnit({ archived: false, });
                                                    }
                                                } else {
                                                    // Toggle the learningUnit archive flag to true if we have one.
                                                    if (modelLearningUnit) {
                                                        changeLearningUnit({ archived: true, });
                                                    }
                                                }
                                            }}
                                        />
                                        <FormText>
                                            {t('learningUnitDetails.help', 'Adding a learning event or resource details is optional, however it will help with more detailed analysis. ')}
                                        </FormText>
                                    </FormGroup>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!isLearningUnit}>
                                    <LearningUnitDetails
                                        model={modelLearningUnit ?? undefined}
                                        change={changeLearningUnit}
                                        validate={validateLearningUnit}
                                        validationErrors={validationErrorsLearningUnit}
                                        currencySymbol={subscription?.currencySymbol ?? '£'}
                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!isTemplate || model.isTemplate}>
                                    <FormGroup>
                                        <Label htmlFor="questionnaireType">{t('editQuestionnaireBase.templateType', 'Template Type')}</Label>
                                        <div className="radio">
                                            {
                                                questionnaireTypesArray.sort().map(item => (
                                                    <label key={item}>
                                                        <input
                                                            type="radio"
                                                            value={item}
                                                            name="questionnaireType"
                                                            checked={model.questionnaireType === item}
                                                            onChange={e => { if (e.currentTarget.checked) { change({ questionnaireType: e.currentTarget.value }); } }}
                                                        />{questionnaireTypeDisplayName(item, t)}
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </FormGroup>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </ConditionalFragment>

                    {/* ********************************************************************************** */}
                    {/* questionnaire section of edit questionnaire - shows if user selects questionnaire. */}
                    {/* ********************************************************************************** */}

                    <ConditionalFragment showIf={activeStage === 'questionnaire'}>
                        <ConditionalFragment showIf={!!lowerLevelErrors}>
                            <Row>
                                <Col className="text-danger text-right mb-2">
                                    {lowerLevelErrors}
                                </Col>
                            </Row>
                        </ConditionalFragment>

                        <SectionsTab
                            model={model}
                            sectionsManager={sectionsManager}
                            validateQuestionnaireSection={validateQuestionnaireSection}
                            questionnaireSectionValidationErrors={questionnaireSectionValidationErrors}
                            questionsManager={questionsManager}
                            validateQuestion={validateQuestion}
                            questionValidationErrors={questionValidationErrors}
                            saveAllDeBounce={saveFormDebounce}
                            isCreate={isTreatAsCreate}
                            validate={validate}
                            setActiveStage={setActiveStage}
                            completedStages={completedStages}
                            isTemplate={isTemplate}
                            analysisTypeManager={analysisTypeManager}
                            validateAnalysisType={validateAnalysisType}
                            analysisTypeValidationErrors={validationErrorsAnalysisType}
                            questionnaireAnalysisTypeLinkManager={questionnaireAnalysisTypeLinksManager}
                            defaultSections={defaultSections ?? []}
                        />
                    </ConditionalFragment>

                    {/* ******************************************************************************************** */}
                    {/* people section of edit questionnaire - shows if user selects people or by default for edits. */}
                    {/* ******************************************************************************************** */}

                    <ConditionalFragment showIf={activeStage === 'people'}>
                        <PeopleTab
                            model={model}
                            usersManager={usersManager}
                            setActiveStage={setActiveStage}
                            isCreate={isTreatAsCreate}
                            saveAllDeBounce={saveFormDebounce}
                            existingSessions={storeSessions}
                            completedStages={completedStages}
                        />
                    </ConditionalFragment>

                    {/* ************************************************************************ */}
                    {/* schedule section of edit questionnaire - shows if user selects schedule. */}
                    {/* ************************************************************************ */}

                    <ConditionalFragment showIf={activeStage === 'schedule'}>
                        <ScheduleTab
                            model={model}
                            setActiveStage={setActiveStage}
                            change={change}
                            saveAllDeBounce={saveFormDebounce}
                            isCreate={isTreatAsCreate}
                            completedStages={completedStages}
                        />
                    </ConditionalFragment>

                    {/* ********************************************************************* */}
                    {/* review section of edit questionnaire - shows if user review schedule. */}
                    {/* ********************************************************************* */}

                    <ConditionalFragment showIf={activeStage === 'review'}>
                        <ReviewTab
                            model={model}
                        />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={activeStage === 'campaign'}>
                        <Row>
                            <Col></Col>
                            <Col xs="auto">
                                {
                                    // no continue button in edit, only during create
                                    isTreatAsCreate ?
                                        <Button color="primary" disabled={!completedStages.find(item => item === 'campaign')}
                                            onClick={e => {
                                                if (validate()) {
                                                    saveFormDebounce();
                                                    setActiveStage('questionnaire');
                                                }
                                            }}>
                                            {t("common.continue", "Continue")}
                                            <> </>
                                            <FontAwesomeIcon icon="caret-right" />
                                        </Button>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    <FormButtons>
                        <h6 className={"savedText"}>{!!isSaving ? t('editQuestionnaireBase.savedText', 'Saved') : t('editQuestionnaireBase.emptySavedText', '')}</h6>
                        <ButtonGroup>
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving}
                                    onClick={async () => { if (isSelfService) { handleIsPublishedClick(); }; await saveFormDebounce({ navigateTo: handleSaveNavigateTo(), }); }}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('editQuestionnaireBase.save', `Save & Close`)}</>
                                </ButtonAsync>
                            </ConditionalFragment>



                            <ConditionalFragment showIf={!isLoading && !model.isPublished && !isTemplate && activeStage === 'review'}>
                                <ButtonAsync color="primary" outline isExecuting={isSaving}
                                    onClick={async () => { handleIsPublishedClick(); await saveFormDebounce({ navigateTo: handleSaveNavigateTo(), }); }}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('editQuestionnaireBase.save', 'Schedule launch')}</>
                                </ButtonAsync>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={activeStage === 'questionnaire'}>
                                <Button onClick={() => toggleImportExportModal()} color="primary" outline>
                                    <FontAwesomeIcon icon="file-alt" />
                                    <> </>
                                    {t('editQuestionnaireBase.importExport', 'Import/Export')}
                                </Button>
                            </ConditionalFragment>
                            <Button color="primary" type="button" outline
                                onClick={async () => { await saveForm({ goBack: false, navigateTo: `${baseRoute}preview/${model.id}/${activeStage}`, }); }}
                            >
                                <FontAwesomeIcon icon="eye" />
                                <> </>
                                {t('common.Preview', 'Preview')}
                            </Button>


                            {
                                isCreate ?
                                    /* To cancel out of a create we also need to archive the questionnaire we just created */
                                    <ButtonAsync color="primary" outline isExecuting={isSaving}
                                        onClick={() => toggleConfirmCancelModal()}>
                                        <FontAwesomeIcon icon="ban" />
                                        <> </>
                                        {t('editQuestionnaireBase.cancel', 'Cancel')}
                                    </ButtonAsync>
                                    :
                                    /* otherwise we just do a normal cancel if we are editing */
                                    <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                        <FontAwesomeIcon icon="times" />
                                        <> </>
                                        {t('common.close', 'Close')}
                                    </Button>
                            }
                        </ButtonGroup>
                    </FormButtons>
                </Form>

                {/* ********************************************************************* */}
                {/* Confirm Cancel Modal - allows user to confirm they want to cancel     */}
                {/* creating this questionnaire, save for later or continue as they were  */}
                {/* ********************************************************************* */}
                <ConditionalFragment showIf={confirmCancelModalIsOpen && !!isCreate}>
                    <ConfirmCancelQuestionnaireModal
                        isOpen={confirmCancelModalIsOpen}
                        toggle={toggleConfirmCancelModal}
                        removeItem={async () => {
                            await remove(model.id);
                            history.goBack();
                        }}
                        saveItem={() => { saveFormDebounce(); history.goBack(); }}
                    />
                </ConditionalFragment>


                {/* Import Export Modal - For importing and exporting Questionnaire Questions*/}
                <ConditionalFragment showIf={importExportModalIsOpen}>
                    <ImportExportQuestionnaireQuestionsModal
                        isOpen={importExportModalIsOpen}
                        questionnaireId={id ?? ''}
                        performImport={performImport}
                        isExecuting={isImporting}
                        importResults={importResults}
                        respondantSessionsHaveStartDate={!!respondantSessionsWithStartDate.length}
                        closeImportExportModal={() => closeImportExportModal()}
                        subscriptionId={model.subscriptionId ?? ''}
                        ImportErrors={performImportErrors}
                    />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};


/**
 * Data we need to capture for each user.
 */
export interface UserData {
    key: string,
    email: string,
    firstName: string,
    lastName: string,
    departmentId: string | null | undefined,
    lineManagerName: string,
    lineManagerEmail: string,
    session: Partial<QuestionnaireRespondentSession>,
}

/**
 * Default Values for UserData 
 */
export const userDataDefaultValues = (): Partial<UserData> => ({
    key: '',
    email: '',
    firstName: '',
    lastName: '',
    departmentId: null,
    lineManagerName: '',
    lineManagerEmail: '',
    session: questionnaireRespondentSessionDefaultValues(),
});