import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonDropdown, ButtonGroup, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { useQuestionnaireListViewModelQuery_items } from "../../api/main/generated/useQuestionnaireListViewModelQuery";
import { QuestionnaireType, questionnaireTypeDisplayName } from "../../api/main/models/codeOnly/QuestionnaireType";
import { useDeleteQuestionnaireCallback } from "../../api/main/questionnaires/useDeleteQuestionnaireCallback";
import { CardsOrTable, CardsOrTableViewMode } from "../shared/cardsOrTable/CardsOrTable";
import { DeleteAnyModal } from "../shared/DeleteAnyModal";
import "./questionnaireTableComponent.scss";

export interface QuestionnaireTableComponentProps {
    items: useQuestionnaireListViewModelQuery_items[],
    title?: string,
    cardClassName?: string,
    viewMode: CardsOrTableViewMode, 
    baseRoute?: string,
    type?: string,
    isDepartment?: boolean,
    isSubscription?: boolean,
    departmentId?: string,
    defaultEmptyNameText: string,
}

/*
 * table component for list of Questionnaires.
 */
export const QuestionnaireTableComponent = (props: QuestionnaireTableComponentProps) => {
    const {
        items,
        title,
        cardClassName,
        viewMode,
        baseRoute,
        type,
        isDepartment,
        isSubscription,
        departmentId,
        defaultEmptyNameText,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // only return a list if there are items to be shown
    // otherwise return an empty node

    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove] = useDeleteQuestionnaireCallback();
    const [deletedItemName, setDeletedItemName] = useState<string>("");
    const [deletedItemId, setDeletedItemId] = useState<string>("");

    return (
        items.length ?
            <>
                <ConditionalFragment showIf={!!title}>
                    <h2 className="campaign-category-header">
                        {t('questionnairesListBase.campaignsTitle', `${title}`)}
                    </h2>
                </ConditionalFragment>
                <CardsOrTable
                    cardClassName={cardClassName}
                    viewMode={viewMode}
                    items={items}
                    onItemClick={item => history.push(`${baseRoute}edit/${item.id}`)}
                    tableHeadings={[
                        t('questionnairesListBase.name', 'Name'),
                        !!isSubscription && !departmentId ? t('questionnairesListBase.department', 'Department') : null,
                        !!type && type === 'Template' ? t('questionnairesListBase.type', 'Type') : null,
                        !!type && type === QuestionnaireType.Campaign ? t('questionnairesListBase.StartDate', 'Start Date') : null,
                        !!type && type === QuestionnaireType.Campaign ? t('questionnairesListBase.EndDate', 'End Date') : null,
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{(!item.name ? defaultEmptyNameText : item.name)}</CardTitle>) : (!item.name ? defaultEmptyNameText : item.name),
                        (item, view) => !!isSubscription && !departmentId ? (view === 'cards' ? (t('questionnairesListBase.departmentCard', 'Department') + ' - ') : '') + (item?.subscriptionDepartment?.name ?? (viewMode === 'cards' ? 'any' : '-')) : null,
                        (item, view) => !!type && type === 'Template' ? (view === 'cards' ? (t('questionnairesListBase.questionnaireTypeCard', 'Type') + ' - ') : '') + (questionnaireTypeDisplayName(item.questionnaireType as QuestionnaireType, t) ?? '') : null,
                        (item, view) => !type || type === QuestionnaireType.Campaign ? (view === 'cards' ? (t('questionnairesListBase.startDateCard', 'Start Date') + ' - ') : '') + ((moment(item.startDate).format("L"))) : null,
                        (item, view) => !type || type === QuestionnaireType.Campaign ? (view === 'cards' ? (t('questionnairesListBase.endDateCard', 'End Date') + ' - ') : '') + ((moment(item.endDate).format("L"))) : null,
                    ]}
                    buttons={(item) => (
                        <Row>
                            <Col xs="auto">
                                <ButtonGroup>
                                    <LinkContainer to={`${baseRoute}edit/${item.id}`}>
                                        <Button className={"btn-outline-questionnaire"} color={"primary"} outline>
                                            <FontAwesomeIcon icon="edit" />
                                            <> {t('common.edit', 'Edit')}</>
                                        </Button>
                                    </LinkContainer>
                                    <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                        <DropdownToggle className={"btn-outline-questionnaire"} color="primary" outline caret>
                                            <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <ConditionalFragment showIf={!item.isTemplate}>
                                                <LinkContainer to={`/subscriptionQuestionnaires/Template/clone/${item.id}`}>
                                                    <DropdownItem className={`text-${type?.toLowerCase() ?? 'primary'}`}>
                                                        <FontAwesomeIcon icon="save" />
                                                        <> </>
                                                        {t('questionnairesListBase.saveAsTemplate', 'Save As Template')}
                                                    </DropdownItem>
                                                </LinkContainer>
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={type === QuestionnaireType.Campaign || type === QuestionnaireType.SelfService}>
                                                {/*<LinkContainer to={`/dashboard/subscription/${item.subscriptionId}/questionnaire/${item.id}/`}>*/}
                                                {/*    <DropdownItem className={`text-${type?.toLowerCase() ?? 'primary'}`}>*/}
                                                {/*        <FontAwesomeIcon icon="chart-line" />*/}
                                                {/*        <> </>*/}
                                                {/*        {t('questionnairesListBase.questionnaireStatistics', 'Questionnaire Statistics')}*/}
                                                {/*    </DropdownItem>*/}
                                                {/*</LinkContainer>*/}
                                                <ConditionalFragment showIf={!isDepartment && !!isSubscription}>
                                                    {/*<LinkContainer to={`/subscriptionQuestionnaires/quick/view/report/${item.id}`}>*/}
                                                    {/*    <DropdownItem className={`text-${type?.toLowerCase() ?? 'primary'}`}>*/}
                                                    {/*        <FontAwesomeIcon icon="file-alt" />*/}
                                                    {/*        <> </>*/}
                                                    {/*        {t('questionnairesListBase.questionnaireReport', 'Questionnaire Report')}*/}
                                                    {/*    </DropdownItem>*/}
                                                    {/*</LinkContainer>*/}
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={!!isDepartment && !!isSubscription}>
                                                    {/*<LinkContainer to={`/departmentQuestionnaires/quick/view/report/${item.id}/department/${departmentId}`}>*/}
                                                    {/*    <DropdownItem className={`text-${type?.toLowerCase() ?? 'primary'}`}>*/}
                                                    {/*        <FontAwesomeIcon icon="file-alt" />*/}
                                                    {/*        <> </>*/}
                                                    {/*        {t('questionnairesListBase.questionnaireReport', 'Questionnaire Report')}*/}
                                                    {/*    </DropdownItem>*/}
                                                    {/*</LinkContainer>*/}
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={!!isSubscription}>
                                                    {/*<LinkContainer to={*/}
                                                    {/*    !isDepartment ? `/subscriptionQuestionnaires/build/report/${item.id}`*/}
                                                    {/*        : `/manageDepartment/build/report/department/${departmentId}/${item.id}`*/}
                                                    {/*}>*/}
                                                    {/*    <DropdownItem className={`text-${type?.toLowerCase() ?? 'primary'}`}>*/}
                                                    {/*        <FontAwesomeIcon icon="file-alt" />*/}
                                                    {/*        <> </>*/}
                                                    {/*        {t('questionnairesListBase.ReportBuilder', 'Report Builder')}*/}
                                                    {/*    </DropdownItem>*/}
                                                    {/*</LinkContainer>*/}
                                                </ConditionalFragment>
                                            </ConditionalFragment>
                                            <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); setDeletedItemName(item.name); }}>
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                            </Col>
                            <ConditionalFragment showIf={item.isPublished}>
                                <Col>
                                    <ButtonGroup className={"see-impacts"}>
                                        <LinkContainer to={`/subscriptionQuestionnaires/overview/${item.id}`}>
                                            <Button color="primary">
                                                <FontAwesomeIcon icon="search" />
                                                    <> {t('questionnairesListBase.see-impacts', 'See IMPACTS')}</>
                                            </Button>
                                        </LinkContainer>
                                    </ButtonGroup>
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    )}
                />


                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={deletedItemName}
                        remove={() => remove(deletedItemId)}
                    />
                </ConditionalFragment>

            </>
            :
            <></>
    );
};