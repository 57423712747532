import { ValidatedInput, ValidatedInputProps } from "pojo-validator-reactstrap";
import { useMemo, useState } from "react";

export interface ValidatedNumberInputProps extends ValidatedInputProps {
    onFormat?: (value: number) => string,
    currencySymbol?: string
}

/**
 * Handles the behaviour of a validated number input field so that it is not formatted until the field loses focus.
 */

export const ValidatedNumberInput = (props: ValidatedNumberInputProps) => {
    const { value, type, onFormat, onFocus: _onFocus, onBlur: _onBlur, currencySymbol, ...rest } = props;

    // Need to know if the focus is on us or not.
    const [isFocused, setIsFocused] = useState<boolean>(false);

    // Get the value formatted as the user wants it formatted.
    const formattedValue = useMemo(() => {
        // If we're focused don't run any potentially expensive formatting that we're not even going to use, we just want the raw value.
        if (isFocused) {
            return !!value && value > 0 ? value : '';
        }

        // Let the input be formatted.
        if (onFormat) {
            // check if we have a forced currency symbol
            if (!!currencySymbol) {
                return currencySymbol + onFormat(parseFloat(value?.toString() ?? '0'));
            }

            return onFormat(parseFloat(value?.toString() ?? '0'));
        }

        // don't know how to format it so just return doing nothing.
        return value;
    }, [value, onFormat, isFocused, currencySymbol]);

    return (<ValidatedInput
        type={type ?? (isFocused ? 'number' : 'text')}
        value={formattedValue}
        onFocus={e => {
            setIsFocused(true);
            if (_onFocus) {
                _onFocus(e);
            }
        }}
        onBlur={e => {
            setIsFocused(false);
            if (_onBlur) {
                _onBlur(e);
            }
        }}
        {...rest}
  />);
};