import { useCallback, useState, } from "react";
import { Button, Row, Col, Form, Spinner, NavItem, NavLink, ListGroup, ListGroupItem, ListGroupItemHeading, } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChangesArray} from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { QuestionnaireRespondentSessionCreateInput } from "../../../api/main/generated/globalTypes";
import { useAddQuestionnaireUserCallback } from "../../../api/main/questionnaireRespondentSessions/useAddQuestionnaireUserCallback";
import { QuestionnaireRespondentSession, questionnaireRespondentSessionDefaultValues } from "../../../api/main/models/QuestionnaireRespondentSession";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { NavTiles } from "../../shared/NavTiles";
import "./questionnaireUsersAdd.scss";
import { QuestionnaireUsersImport } from "./QuestionnaireUsersImport";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { useQuestionnaireUsersAddSupportingData } from "../../../api/main/questionnaires/viewModels/useQuestionnaireUsersAddSupportingData";
import { NextBackButtons } from "../../shared/NextBackButtons";
import { UserData } from "../../questionnaires/EditQuestionnaireBase";

interface QuestionnaireUsersAddBaseProps {
    isCreate?: boolean,
}

// this is used when creating a questionnaire and guiding the user through the 2 step wizard - add users is step 2
export const QuestionnaireUsersCreate = () => (<QuestionnaireUsersAdd isCreate={true} />);

/**
 * Add a participant to a questionnaire.
 */
export const QuestionnaireUsersAdd = (props: QuestionnaireUsersAddBaseProps) => {
    const { isCreate = false, } = props

    // QuestionnaireId From the Params.
    const { id } = useParams<{ id: string }>();

    // Department Id from the params 
    const { departmentId } = useParams<{ departmentId: string }>();

    // Get the current users subscriptionId  
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // get all supporting data
    // departments for dropdown on each user
    // profiles to match email when entered
    // questionnaire for details 
    // sessions so we can check if user is already on questionnaire

    const { data: { profiles: AllProfiles, questionnaire }, isLoading, errors: loadErrors } = useQuestionnaireUsersAddSupportingData({ subscriptionId: currentUserSubscriptionId ?? undefined, questionnaireId: id });

    // When adding users, we only look in profiles in this subscription so that emails from other subscriptions can be used but we don't prepopulate 
    // any potentially sensitive information from a different subscription
    //const subscriptionProfiles = AllProfiles?.filter(item => item.subscriptionId === currentUserSubscriptionId);

    // get all current sessions so we can validate additions
    const AllSessions = questionnaire?.questionnaireRespondentSessions ?? [];

    // allows us to set the correct active button for selections
    const [selectionType, setSelectionType] = useState<string>('manual');

    // create a new QuestionnaireRespondentSession for each user manually entered
    const [save, { errors: saveErrors }] = useAddQuestionnaireUserCallback();

    // As we don't have a model that stores everything we need for each user, we've created one called UserData (see below).
    // We store an array of these and render their details on screen.  We will split this back out into
    // users and respondant sessions when we save.
    const usersManager = useChangesArray<UserData, string>([], item => item.key);

    const history = useHistory();

    // validation for the usersManager items
    //const [validateUserData, userDataValidationErrors] = useValidatorArrayCallback<UserData>((myModel, validation, fieldsToCheck) => {
    //    const rules = {
    //        // add any rules for individual users here
    //    };

    //    validation.checkRules(rules, fieldsToCheck);

    //}, []);

    // QuestionnaireRespondantSessionDefaultValues used for saving new respondant sessions (see below.)
    const newSession = (): Partial<QuestionnaireRespondentSession> => ({
        ...questionnaireRespondentSessionDefaultValues(),
        questionnaireId: id,
        subscriptionId: currentUserSubscriptionId,
    });

    // keep arrays so we can add messages for accepted and rejected users this time in
    const [justAddedUsers, setJustAddedUsers] = useState<UserData[]>([]);
    const [justRejectedUsers, setJustRejectedUsers] = useState<UserData[]>([]);

    // flag to force rerender of the user input area after save and clear
    const [isReset, setIsReset] = useState<boolean>(false);

    // Main validation.
    const [validate] = useValidatorCallback((validation, fieldsToCheck) => {

        const rules = {
            // Validate userData
            //userData: () => usersManager.model.filter(it => !validateUserData(it)).length ? t('questionnaireUsersAdd.userDataInvalid', 'One or more of the Users are invalid') : '',

        };

        validation.checkRules(rules, fieldsToCheck);

    }, [
        usersManager,
    ]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        // Save the participants.
        for (const item of usersManager.added) {
            if (!!item.email) {
                await save(
                    { ...newSession() as QuestionnaireRespondentSessionCreateInput, lineManagerEmail: item.lineManagerEmail, lineManagerName: item.lineManagerName, subscriptionDepartmentId: item.departmentId },
                    item.firstName,
                    item.lastName
                );
            }
        }
        usersManager.markAsSaved();

        for (const item of usersManager.updated) {
            if (!!item.email) {
                await save(
                    { ...newSession() as QuestionnaireRespondentSessionCreateInput, lineManagerEmail: item.lineManagerEmail, lineManagerName: item.lineManagerName, subscriptionDepartmentId: item.departmentId },
                    item.firstName,
                    item.lastName
                );
            }
        }
        usersManager.markAsSaved();

        // add users to array of saved users or rejected users
        let allNewUsers = [...justAddedUsers];
        let allRejectedUsers = [...justRejectedUsers];
        usersManager.model.forEach((item) => {
            // Check if the user is already on this questionnaire.
            // If they are they will have been rejected during the save but we need to reject them here.
            const thisUserId = AllProfiles.find(pro => pro.user.email === item.email)?.userId;
            const session = AllSessions.find(sess => sess.userId === thisUserId);
            // see if we have successfully added the user already but they were not there when we originally came into the view
            const isJustAdded = allNewUsers.find(us => us.email === item.email);

            if (!!session || isJustAdded) {
                // reject anyone already on the questionnaire
                allRejectedUsers.push(item);
            } else {
                allNewUsers.push(item);
            }
        });
        setJustAddedUsers(allNewUsers);
        setJustRejectedUsers(allRejectedUsers);

        // clear the UserData ready for the next group of 5
        usersManager.model = [];

        // force a rerender of the cleared details otherwise the old ones stay on the form
        setIsReset(true);
        setIsReset(false);

        // Navigate to the next screen if set.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } //else {
        // we don't want this to go back if no navigate is set as the user can enter as many blocks of 5 users as they want. 
        // Let them use close if they want to.
        // history.goBack();
        //}

    }, [validate, save, history, usersManager, newSession, justAddedUsers, setJustAddedUsers, justRejectedUsers, setJustRejectedUsers, setIsReset,]);

    const handleNextButton = useCallback(async (itemId: string | undefined) => {
        if (!!isLoading) {
            return;
        }

        let url = '';
        if (questionnaire?.questionnaireType === QuestionnaireType.Campaign) {
            if (!departmentId) {
                url = `/manage/questionnaires/schedule/${itemId}`;
            } else {
                url = `/departmentQuestionnaires/${departmentId}/schedule/${itemId}`;
            }
        } else {
            if (!departmentId) {
                url = `/manage/questionnaires/summary/${itemId}`;
            } else {
                url = `/departmentQuestionnaires/${departmentId}/summary/${itemId}`;
            }
        }
        await saveForm({
            navigateTo: url ?? undefined,
        });


    }, [questionnaire?.questionnaireType, saveForm, departmentId, isLoading]);

    const { t } = useTranslation();

    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerWithPills"}>
                    <Col xs="">
                        <h1>
                            {
                                isCreate ?
                                    t('questionnaireUsersAdd.createHeading', 'Step 2: Add Participants')
                                    :
                                    t('questionnaireUsersAdd.heading', 'Add Participants To ') + questionnaire?.name
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer className={"add-users-main-container"}>
                <AlertOnErrors errors={[loadErrors, saveErrors, saveFormErrors]} />
                <Row>
                    <Col xs={12} className="mb-2">
                        <span className="text-muted">
                            {t('questionnairsUsersAdd.participants.helpText', 'You can either manually add up to 5 people at a time and save them, or for larger numbers of people you might prefer to import them by uploading a .csv file.')}
                        </span>
                    </Col>
                    <Col xs={12} md="auto">
                        <Row>
                            <Col className={"tile-column"}>
                                <NavTiles className="nav-tiles-large">
                                    <NavItem key={'manualAdd'}>
                                        <NavLink className={selectionType === 'manual' ? 'active' : ''} to="/" onClick={e => { setSelectionType('manual'); }}>
                                            <div className="navitem-text">{t('questionnairsUsersAdd.manual', 'Add Participants')}</div>
                                            <FontAwesomeIcon icon="plus" className="nav-icon" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={'importAdd'}>
                                        <NavLink className={selectionType === 'import' ? 'active' : ''} to="/" onClick={e => { setSelectionType('import'); }}>
                                            <div className="navitem-text">{t('questionnairsUsersAdd.import', 'Import Participants')}</div>
                                            <FontAwesomeIcon icon="upload" className="nav-icon" />
                                        </NavLink>
                                    </NavItem>
                                </NavTiles>

                                <ConditionalFragment showIf={selectionType === "manual" && !!justAddedUsers.length}>
                                    <div className="current-user-list">
                                        <ListGroup>
                                            <ListGroupItemHeading>{t('questionnairsUsersAdd.addedUserList.header', 'Just Added')}</ListGroupItemHeading>
                                            <span className="text-muted">
                                                {t('questionnairsUsersAdd.addedUserList.help', 'These users have been added to this campaign manually during this session')}
                                            </span>
                                            {justAddedUsers.map((item) => {
                                                return (
                                                    <ListGroupItem>{item.email}</ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </div>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={selectionType === "manual" && !!justRejectedUsers.length}>
                                    <div className="current-user-list">
                                        <ListGroup>
                                            <ListGroupItemHeading>{t('questionnairsUsersAdd.rejectedUserList.header', 'Just Rejected')}</ListGroupItemHeading>
                                            <span className="text-muted">
                                                {t('questionnairsUsersAdd.addedUserList.help', 'You tried to enter these users manually and they have been rejected. This is usually because they are already participants on this campaign.')}
                                            </span>
                                            {justRejectedUsers.map((item) => {
                                                return (
                                                    <ListGroupItem className="rejected">{item.email}</ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </div>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md="">
                        <ConditionalFragment showIf={selectionType === "manual"}>
                            {/*QuestionnaireUserAddDetails 5x to allow for 5 participants to be added to a questionnaire at once*/}
                            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                                <Col className={"add-user-details-container"}>
                                    <ConditionalFragment showIf={!isReset}>
                                        {
                                            [...Array(5)].map((value: undefined, index) => {
                                                return (
                                                    <div key={index}>
                                                        {/*<QuestionnaireUserAddDetails*/}
                                                        {/*    itemNumber={index}*/}
                                                        {/*    usersManager={usersManager}*/}
                                                        {/*    validate={validateUserData}*/}
                                                        {/*    validationErrors={userDataValidationErrors(usersManager.model[index]?.key ?? '')}*/}
                                                        {/*    subscriptionDepartments={subscriptionDepartments}*/}
                                                        {/*    subscriptionProfiles={subscriptionProfiles}*/}
                                                        {/*/>*/}
                                                    </div>
                                                );
                                            })
                                        }
                                    </ConditionalFragment>
                                </Col>
                            </Form>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={selectionType === "import"}>
                            <QuestionnaireUsersImport
                                questionnaireId={id ?? ''}
                            />
                        </ConditionalFragment>

                    </Col>
                </Row>
                <FormButtons>
                    <ConditionalFragment showIf={selectionType === "manual"}>
                        <ButtonAsync color="primary" isExecuting={isSaving}
                            onClick={
                                async () => {
                                    await saveForm();
                                }}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> {t('common.next', 'Save')}</>
                        </ButtonAsync>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!isCreate}>
                        {/* Close the form without saving anything else if not in create */}
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ConditionalFragment>
                </FormButtons>
                <ConditionalFragment showIf={isCreate}>
                    <NextBackButtons
                        showBack={true}
                        showNext={true}
                        handleNextButton={handleNextButton}
                        itemId={questionnaire?.id}
                        isSaveIncluded={true}
                        isSaving={isSaving}
                    />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};

