import { AnalysisType } from "../../../api/main/models/AnalysisType";
import { isResponseTypeForMeasure, QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { LearningUnit } from "../../../api/main/models/LearningUnit";
import { Profile } from "../../../api/main/models/Profile";
import { Question } from "../../../api/main/models/Question";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { QuestionnaireAnalysisTypeLink } from "../../../api/main/models/QuestionnaireAnalysisTypeLink";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionnaireSection } from "../../../api/main/models/QuestionnaireSection";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { TagLikeItem } from "../../../shared/tagSelector/TagSelector";
import { AnalyserReportSection } from "./Analyser";

export interface AnalyserDataInterrogationProps {
    questionnaires: Questionnaire[],
    questions: Question[],
    questionResponses: QuestionResponse[],
    learners: Profile[],
    questionnaireRespondantSessions: QuestionnaireRespondentSession[],
    analysisTypes: AnalysisType[],
    allAnalysisTypes: boolean,
    questionnaireAnalysisTypeLinks: QuestionnaireAnalysisTypeLink[],
    impacts: string[],
    measures: string[],
    sections: QuestionnaireSection[],
    learningUnits: LearningUnit[];
}

export interface AnalyserInterrogationResult {
    categoryResults: { analysisType: AnalysisType, data: {  result: number, arrow?: 'up' | 'down' } }[],
    sectionResults: AnalyserReportSection[],
    learnerResults: { totalLearners: number, totalCost: number, costPerLearner: number },
    resultsPieChartResults: { completedProfiles: TagLikeItem[], incompletedProfiles: TagLikeItem[] }
}

export function InterrogateAnalyserData(props: AnalyserDataInterrogationProps) {
    const { questionnaires, learners,
        questionnaireRespondantSessions, analysisTypes, allAnalysisTypes,
        questionnaireAnalysisTypeLinks, impacts,
        measures, sections, learningUnits, questions, questionResponses } = props; 
        
    const ret: AnalyserInterrogationResult = {
        categoryResults: [],
        sectionResults: [],
        learnerResults: { totalLearners: 0, totalCost: 0, costPerLearner: 0 },
        resultsPieChartResults: { completedProfiles: [], incompletedProfiles: [] }
    };

    // Get number of Measures from the QuestionResponseType enum
    const allQuestionResponseTypes = Object.values(QuestionResponseType);
    const measureQuestionResponseTypes = allQuestionResponseTypes.filter(item => {
        return isResponseTypeForMeasure(item) && item !== QuestionResponseType.YesNo;
    });

    const numberOfMeasureQuestionResponseTypes = measureQuestionResponseTypes.length;

    // Apply impacts, measures, analysisTypes filters. we pull in the 'all' bool for analysisTypes but not the others since analysisTypes has a non-static set of possible values.
    const questionsFiltered = questions?.filter(q => {
        const impactFilter = impacts.length === Object.values(QuestionType).length  || impacts.includes(q.questionType);
        const analysisTypeFilter = allAnalysisTypes || !!analysisTypes.find(a => q.analysisTypeId === a.id);
        const measureFilter = measures.length === numberOfMeasureQuestionResponseTypes || measures.includes(q.responseType);

        return impactFilter && analysisTypeFilter && measureFilter;
    });
    const questionResponsesFiltered = questionResponses?.filter(q => questionsFiltered.find(it => q.questionId === it.id) && learners.find(l => l.userId === q.userId));

    // Category Analysis
    for (let i = 0; i < analysisTypes.length; i++) {
        const analysisType = analysisTypes[i];
        const questionsForAnalysisType = questionsFiltered?.filter(q =>
            q.analysisTypeId === analysisType.id &&
            q.responseType !== QuestionResponseType.PickOne &&
            q.responseType !== QuestionResponseType.MultipleChoice &&
            q.responseType !== QuestionResponseType.TextBoxes &&
            q.responseType !== QuestionResponseType.YesNo &&
            q.responseType !== QuestionResponseType.Scale);

        // If there are no questions for this analysis type, skip it
        if (!questionsForAnalysisType.length) {
            continue;
        }

        let percentageTotal = 0;
        let numberOfPercentages = 0;

        // Handle each question
        for (let q = 0; q < questionsForAnalysisType.length; q++) {
            const questionForAnalysisType = questionsForAnalysisType[q];
            let allResponsesForQuestion = questionResponsesFiltered?.filter(r => r.questionId === questionForAnalysisType.id);
            if (!allResponsesForQuestion.length) {
                continue;
            }


            // Convert responses to a  percentage change for each response or set of responses to each question and average all the results

            if (questionForAnalysisType.responseType === QuestionResponseType.ComparisonScale) {
                // Comparison Scale

                // Get all the unique respondent session ids
                const distinctRespondentSessionIds: Array<string> = [];
                for (let i = 0; i < allResponsesForQuestion.length; i++) {
                    if (!distinctRespondentSessionIds.includes(allResponsesForQuestion[i].questionnaireRespondentSessionId ?? '')) {
                        distinctRespondentSessionIds.push(allResponsesForQuestion[i].questionnaireRespondentSessionId ?? '');
                    }
                }

                for (let rs = 0; rs < distinctRespondentSessionIds.length; rs++) {
                    const respondentSessionId = distinctRespondentSessionIds[rs];
                    // Check for a pair of responses for each respondent session
                    const responsesForSession = allResponsesForQuestion.filter(r => r.questionnaireRespondentSessionId === respondentSessionId).sort((a, b) => a.displayOrder - b.displayOrder);
                    if (responsesForSession.length !== 2) {
                        continue;
                    }

                    // Handle the pair of responses
                    const before = responsesForSession[0].amountInput;
                    const after = responsesForSession[1].amountInput;

                    // Adjust the amounts to be a percentage based on the number of options in the scale
                    const beforeToPercentage = !questionForAnalysisType.isPercentage ? before / questionForAnalysisType.scaleMax * 100 : before / questionForAnalysisType.scaleMax * 10;
                    const afterToPercentage = !questionForAnalysisType.isPercentage ? after / questionForAnalysisType.scaleMax * 100 : after / questionForAnalysisType.scaleMax * 10;
                    const difference = afterToPercentage - beforeToPercentage;

                    percentageTotal += difference;
                    numberOfPercentages++;
                }
            } else if (questionForAnalysisType.responseType === QuestionResponseType.Percentage) {
                // Percentage
                for (let r = 0; r < allResponsesForQuestion.length; r++) {
                    const response = allResponsesForQuestion[r];
                    const percentage = response.amountInput;
                    percentageTotal += percentage;
                    numberOfPercentages++;
                }
            } else if (questionForAnalysisType.responseType === QuestionResponseType.CurrencyAmount || questionForAnalysisType.responseType === QuestionResponseType.Number) {
                // Currency amount or Number
                const analysisTypeLink = questionnaireAnalysisTypeLinks.find(l => l.questionnaireId === questionForAnalysisType.questionnaireId && l.analysisTypeId === analysisType.id);
                const baseAmount = !!analysisTypeLink ? analysisTypeLink.baseAmount : analysisType.baseAmountDefault;

                for (let r = 0; r < allResponsesForQuestion.length; r++) {
                    const response = allResponsesForQuestion[r];
                    const amount = response.amountInput;
                    const percentage = baseAmount === 0 ? 100 : (amount / baseAmount) * 100;
                    percentageTotal += percentage;
                    numberOfPercentages++;
                }
            }
        }

        if (numberOfPercentages !== 0) {
            // Work out the average percentage change for this analysis type
            // Commented this out as we want to show a total I think
            // const averagePercentage = percentageTotal / numberOfPercentages;

            //ret.categoryResults?.push({ analysisType: analysisType, data: { result: averagePercentage < 0 ? averagePercentage * -1 : averagePercentage, arrow: averagePercentage >= 1 ? 'up' : 'down' } });
            ret.categoryResults?.push({ analysisType: analysisType, data: { result: percentageTotal < 0 ? percentageTotal * -1 : percentageTotal, arrow: percentageTotal >= 1 ? 'up' : 'down' } });
        }
    }

    // Section analysis
    sections.forEach(section => {
        const questionsForSection = questions.filter(q => q.questionnaireSectionId === section.id);
        if (!questionsForSection.length) { return; }
        const data: { question: Question, responses: QuestionResponse[], include: boolean }[] = [];

        // Go through each question and collect the responses
        questionsForSection.forEach(question => {
            const responsesToQuestion = questionResponses.filter(q => q.questionId === question.id);
            if (!responsesToQuestion.length) { return; }
            data.push({ question: question, responses: responsesToQuestion, include: true});
        });
        if (!data.length) {
            return;
        }
        // We need the questionnaire name so we can group all the sections by name but separate them by questionnaire
        // but only if we are dealing with more than 1 questionnaire
        const sectionQuestionnaire = questionnaires.length > 1 ? questionnaires.find(item => item.id === section.questionnaireId) : undefined;

        // Once that's done, push the section up and move on to the next
        ret.sectionResults?.push({ id: section.id, sectionName: section.name, questionnaireName: sectionQuestionnaire?.name ?? '', sectionQuestionData: data});
    });

    // Learner analysis
    // Get the totals for each questionnaire
    const data: { questionnaire: Questionnaire, totalLearners: number, totalCost: number, costPerLearner: number }[] = [];
    // Pie chart data
    const pieChartData: { completedProfiles: TagLikeItem[], incompletedProfiles: TagLikeItem[] } = { completedProfiles: [], incompletedProfiles: [] };
    questionnaires.forEach(questionnaire => {
        const learningUnitForQuestionnaire = learningUnits.find(l => l.questionnaireId === questionnaire.id);
        const sessionsForQuestionnaire = questionnaireRespondantSessions.filter(s => s.questionnaireId === questionnaire.id);
        const learnersInQuestionnaire = learners.filter(l => !!sessionsForQuestionnaire.find(s => s.userId === l.userId));

        if (!!learningUnitForQuestionnaire) {
            const totalLearners = learnersInQuestionnaire.length;
            const totalCost = learningUnitForQuestionnaire.cost !== 0 ? learningUnitForQuestionnaire.cost : totalLearners * learningUnitForQuestionnaire.costPerLearner;
            const costPerLearner = learningUnitForQuestionnaire.costPerLearner ?? 0;
            data.push({ questionnaire: questionnaire, totalLearners: totalLearners, totalCost: totalCost, costPerLearner: costPerLearner });
        }

        // compile pie chart data
        // Completed
        const completedProfilesAsTags = () => {
            const completedProfiles = learnersInQuestionnaire?.filter(l => sessionsForQuestionnaire?.find(session => session.endDate !== null && session.userId === l.userId));
            const completeRet: Array<TagLikeItem> = [];
            if (!!completedProfiles) {
                completedProfiles.forEach(profile => {
                    completeRet.push({ id: profile.userId, name: profile.firstName + " " + profile.lastName });
                });
            }
            return completeRet;
        }
        // Incompleted
        const incompletedProfilesAsTags = () => {
            const incompletedProfiles = learnersInQuestionnaire?.filter(l => sessionsForQuestionnaire?.find(session => session.endDate === null && session.startDate !== null && session.userId === l.userId));
            const incompleteRet: Array<TagLikeItem> = [];
            if (!!incompletedProfiles) {
                incompletedProfiles.forEach(profile => {
                    incompleteRet.push({ id: profile.userId, name: profile.firstName + " " + profile.lastName });
                })
            }
            return incompleteRet;
        }
        completedProfilesAsTags().forEach(tag => pieChartData.completedProfiles.push(tag));
        incompletedProfilesAsTags().forEach(tag => pieChartData.incompletedProfiles.push(tag));
    });
    // Then average these out
    const totalLearners = data.reduce((acc, val) => acc + val.totalLearners, 0);
    const totalCost = data.reduce((acc, val) => acc + val.totalCost, 0);
    const costPerLearner = totalLearners > 0 ? (totalCost / totalLearners).toFixed(2) : 0;
    ret.learnerResults = { totalLearners: totalLearners, totalCost: totalCost, costPerLearner: Number(costPerLearner) };
    ret.resultsPieChartResults = pieChartData;

    return ret;
}